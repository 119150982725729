.iconManage {
    width: 60px;
    height: 60px;
}
.clientLogo {
    margin-right: 10px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.title {
    font-weight: bold;
}
.imageDiv{
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}